import { useState } from "react";
import { FreshChallengerHeaderCard } from "../components/gallery/FreshChallengerHeaderCard";
import { ChallengerHeaderCard } from "../components/gallery/ChallengerHeaderCard";
import { Gallery } from "../components/gallery/Gallery";
// import { ThankModal } from "../components/modal/ThankModal";
import { useTranslation } from "react-i18next";
import { ModeratorHeaderCard } from "../components/gallery/ModeratorHeaderCard";

// const SHOULD_SHOW_MODAL = true;

type HomePageProps = {
  hasAlreadySubmitted?: boolean;
  isFnacModerator?: boolean;
};

export const HomePage: React.FC<HomePageProps> = ({
  hasAlreadySubmitted = false,
  isFnacModerator = false,
}: HomePageProps) => {
  const { t } = useTranslation();
  const [shouldResfreshStats, setShouldRefreshStats] = useState<number>(
    Date.now()
  );

  const handlePropagateRefresh = () => {
    setShouldRefreshStats(Date.now());
  };

  // // TODO FNAC @Aurel: show the Thanks you for your submission modal
  // const [isThankModalOpen, setIsThankModalOpen] = useState(SHOULD_SHOW_MODAL);

  return (
    <div className="flex flex-col justify-between bg-fnac-gray py-2 px-8 w-full">
      {isFnacModerator ? (
        <ModeratorHeaderCard shouldResfreshStats={shouldResfreshStats} />
      ) : hasAlreadySubmitted ? (
        <ChallengerHeaderCard />
      ) : (
        <FreshChallengerHeaderCard />
      )}
      <Gallery
        title={t("Fnac.Gallery.Title")}
        isFnacModerator={isFnacModerator}
        handlePropagateRefresh={handlePropagateRefresh}
      />
    </div>
  );
};

import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import timeStampParam from "../helpers/api";
import { Generation } from "../types";

const generationsToUrls = (generations: Generation[]) =>
  generations.flatMap(
    ({ generatedImagesUrl }: Generation) => generatedImagesUrl
  );

const GenerationsSection = () => {
  const { keycloak } = useKeycloak();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [generations, setGenerations] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!keycloak.token) return;

    setIsLoading(true);

    fetch(
      `${
        import.meta.env.VITE_API_URL
      }/my-generations${timeStampParam}&page=${page}`,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        setGenerations((prev) => [
          ...prev,
          ...generationsToUrls(data.generations),
        ]);

        setTotalPage(data.totalPages);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [keycloak, page]);

  return (
    <>
      <h2 className="font-extrabold text-2xl md:text-3xl mb-8">
        {t("Generations.Title")}
      </h2>

      {isError && <p className="text-red mb-3">{t("Generations.Error")}</p>}

      {generations.length === 0 && (
        <p>
          {t("Generations.NoGenerations")}
          <br />
          <a href="/creator" className="text-primary not-italic underline">
            {t("Generations.BtnStart")}
          </a>
        </p>
      )}

      <div className="grid gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {generations.map((url) => (
          <a key={url} href={url} target="_blank">
            <img src={url} alt="generated image" className="rounded-md" />
          </a>
        ))}
      </div>

      {page < totalPage && (
        <button
          className="p-2 border rounded-md mt-4 block mx-auto hover:bg-primary hover:text-grey"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={isLoading}
        >
          {t("Generations.LoadMore")}
        </button>
      )}
    </>
  );
};

export default GenerationsSection;

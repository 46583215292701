import { lazy, useEffect, useState } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { ProtectedLayout } from "../helpers/ProtectedLayout";
import {
  teamManagementEnabled,
  fnacEnabled,
  fnacStopped,
} from "../helpers/FeatureFlags";
import NotFoundPage from "./NotFound";
import TeamManagementPage from "./TeamManagement";
import HomePage from "./Home";
export const WhiteboardPage = lazy(() => import("./Whiteboard"));

import BeinkLayout from "../helpers/BeinkLayout";
import { BeinkLayout as BeinkLayoutV2 } from "../v2/components/layout/BeinkLayout";
import { WelcomePage } from "../v2/pages/WelcomePage";
import { HomePage as ContestPage } from "../v2/pages/HomePage";
import { MyCreationsPage as HomePageV2 } from "../v2/pages/MyCreationsPage";
import { useKeycloak } from "@react-keycloak/web";

const router = ({
  hasAlreadySubmitted,
  isFnacAdherent,
  isFnacUser,
  isFnacDemo,
  isFnacModerator,
}: {
  hasAlreadySubmitted: boolean;
  isFnacAdherent: boolean;
  isFnacUser: boolean;
  isFnacDemo: boolean;
  isFnacModerator: boolean;
}) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        {fnacEnabled && !fnacStopped && (
          <Route path="/fnac" element={<WelcomePage />} />
        )}

        {fnacEnabled &&
        !fnacStopped &&
        (isFnacUser || isFnacDemo || isFnacModerator) ? (
          <Route path="/" element={<ProtectedLayout />}>
            <Route element={<BeinkLayoutV2 />}>
              <Route
                path="/gallery"
                element={<ContestPage 
                    hasAlreadySubmitted={hasAlreadySubmitted}
                    isFnacModerator={isFnacModerator} />
                }
              />
              <Route path="/" element={<HomePageV2 />} />
              <Route path="*" element={<NotFoundPage />}></Route>
            </Route>
            <Route
              path="/creator"
              element={
                <WhiteboardPage
                  hasAlreadySubmitted={hasAlreadySubmitted}
                  isFnacAdherent={isFnacAdherent}
                  isFnacUser={isFnacUser}
                  isFnacDemo={isFnacDemo}
                  isFnacModerator={isFnacModerator}
                />
              }
            />
          </Route>
        ) : (
          <Route path="/" element={<ProtectedLayout />}>
            <Route element={<BeinkLayout />}>
              <Route index element={<HomePage />} />
              {teamManagementEnabled && (
                <Route path="/team" element={<TeamManagementPage />} />
              )}
              <Route path="*" element={<NotFoundPage />}></Route>
            </Route>
            <Route path="/creator" element={<WhiteboardPage />} />
          </Route>
        )}

        <Route path="*" element={<NotFoundPage />}></Route>
      </>
    )
  );

// Parse Fnac end date (YYYYmmdd format) to js date
function parseFnacPlusEndDate(str: string) {
    if (str.length !== 8) {
      return;
    }

    const y = Number(str.substring(0, 4)),
          m = Number(str.substring(4, 6)),
          d = Number(str.substring(6, 8));
    var D = new Date(y, m - 1, d);

    return (D.getFullYear() == y && D.getMonth() == m - 1 && D.getDate() == d) ? D : undefined;
}

const Router = () => {
  const { initialized, keycloak } = useKeycloak();
  if (!initialized) return;

  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isFnacUser = userRoles.includes("beink-fnac-user");
  const isFnacDemo = userRoles.includes("beink-fnac-demo");
  const isFnacModerator = userRoles.includes("beink-fnac-moderator");
  const [isFnacAdherent, setIsFnacAdherent] = useState<boolean>(false);
  const [hasAlreadySubmitted, setHasAlreadySubmitted] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  
  if (isFnacUser) {
    useEffect(() => {
      keycloak.loadUserInfo().then((userInfo: any) => {
        let isAdherent = false;

        const fnacPlus = userInfo.membership_is_fnacplus?.toLocaleLowerCase();
        const fnacPlusEndDateStr = userInfo.membership_enddate?.toLocaleLowerCase();
        const fnacOne = userInfo.membership_is_adherentone?.toLocaleLowerCase();

        if (fnacOne == "true" || fnacOne == "one") {
          isAdherent = true;
        } else if (fnacPlus == "true") {
          // Fnac Plus: need to check that membership end date is >= now
          const fnacPlusEndDate = parseFnacPlusEndDate(fnacPlusEndDateStr);
          if (!fnacPlusEndDate) {
            console.error("failed to parse Fnac Plus end date with YYYYmmdd format: ", fnacPlusEndDateStr);
          } else {
            const now = new Date();
            const nowWithoutTime = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            if (fnacPlusEndDate >= nowWithoutTime) {
              isAdherent = true;
            }
          }
        }

        fetch(`${import.meta.env.VITE_API_URL}/event/fnac70/challenger-info`, {
          headers: { authorization: `Bearer ${keycloak.token}` },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) return;
    
            setHasAlreadySubmitted(data.hasAlreadySubmitted);
            setLoading(false);
          })
          .catch((err) => {
            console.error("Oups, An error has occurred", err);
          });

        setIsFnacAdherent(isAdherent);
      });
    }, []);

    // Force waiting for the Fnac membership status as it is needed for the whiteboard
    // rendering
    if (isLoading) {
      return;
    }
  }

  return (
    <RouterProvider
      router={router({ hasAlreadySubmitted, isFnacAdherent, isFnacUser, isFnacDemo, isFnacModerator })}
    />
  );
};

export default Router;

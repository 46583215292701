import { useTranslation } from "react-i18next";
import { ShareBox } from "../social/ShareBox";
import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

export const ChallengerHeaderCard: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [img, setImg] = useState<string>("/v2/my-card.png");

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(new URL("/event/fnac70/submission", import.meta.env.VITE_API_URL), {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("DATA", data);
        if (data.error) return;

        setImg(data.url);
      });
  }, [keycloak]);

  return (
    <div className="flex justify-between py-8">
      <div className="flex flex-col text-3xl uppercase pr-10 justify-center min-w-64 max-w-64 w-64 xl:min-w-96 xl:max-w-96 xl:w-96">
        <h1 className="font-gilroy-bold text-white">
          {t("Fnac.MyContest.Entry")}
        </h1>
        <p className="font-ccsign font-bold text-fnac-mustard">
          {t("Fnac.MyContest.Fnac70")}
        </p>
      </div>
      <div className="w-72">
        <img
          className="max-w-64 max-h-40 w-64 h-40 min-w-64 min-h-40"
          src={img}
        ></img>
      </div>
      <ShareBox />
    </div>
  );
};

import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

import GenerationsSection from "../../components/Generations";

export const MyCreationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo: any) => {
      setUserName((userInfo as { given_name: string }).given_name);
    });
  }, [keycloak]);

  return (
    <div className="p-8">
      <h1 className="font-extrabold text-2xl md:text-5xl mb-8">
        {t("Home.Welcome")} {userName},
      </h1>
      <GenerationsSection />
    </div>
  );
};

import { FnacClaim } from "../components/auth/FnacClaim";
import { FnacLanding } from "../components/auth/FnacLanding";

export const WelcomePage: React.FC = () => {
  return (
    <div className="flex justify-between w-dvw h-dvh beink-radial-gradient">
      <FnacClaim />
      <FnacLanding />
    </div>
  );
};

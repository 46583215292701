import { useKeycloak } from "@react-keycloak/web";
import i18next, { changeLanguage } from "i18next";
import {
  Heart,
  Languages,
  LogOut,
  PenTool,
  UserRound,
  Users,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  fnacEnabled,
  teamManagementEnabled,
} from "../../../../helpers/FeatureFlags";

export const NavBar: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  // TODO FNAC @Aurel: Ultimately, it won't be handled here.
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isTeamManager = userRoles.includes("beink-users-team-manager");

  const isTeamDisplayed = teamManagementEnabled && isTeamManager;

  const toggleLang = () => {
    changeLanguage(i18next.language === "fr" ? "en" : "fr");
  };

  return (
    <aside className="bg-fnac-dark-gray w-64 shrink-0 flex flex-col py-8 border-t border-fnac-gray">
      <nav className="flex flex-col grow">
        <>
          <NavLink
            to="/gallery"
            className={({ isActive }) =>
              isActive
                ? "flex justify-start px-6 py-5 space-x-2 items center text-fnac-mustard font-ccsign text-base bg-fnac-gray"
                : "flex justify-start px-6 py-5 space-x-2 items center text-fnac-mustard font-ccsign text-base hover:bg-grey-400/50"
            }
          >
            <Heart width={22} />
            <span className="">{t("Home.NavTitle.Contest")}</span>
          </NavLink>
        </>
        <>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "md:p-6 p-4 bg-fnac-gray w-full flex items-center gap-3"
                : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
            }
          >
            <PenTool width={22} />
            <span className="hidden md:block">
              {t("Home.NavTitle.MyCreations")}
            </span>
          </NavLink>
        </>
        {!fnacEnabled && (
          <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
            {t("Home.NavTitle.Profile")}
          </div>
        )}

        {!fnacEnabled && (
          <>
            <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
              {t("Home.NavTitle.Settings")}
            </div>
            <NavLink
              to={keycloak.createAccountUrl()}
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 bg-fnac-gray w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              <UserRound width={22} />
              <span className="hidden md:block">Mon Compte</span>
            </NavLink>
          </>
        )}
        {!fnacEnabled && isTeamDisplayed && (
          <>
            <div className="p-6 pb-2 hidden md:block text-primary text-sm uppercase">
              {t("Home.NavTitle.Team")}
            </div>
            <NavLink
              to="/team"
              className={({ isActive }) =>
                isActive
                  ? "md:p-6 p-4 bg-fnac-gray w-full flex items-center gap-3"
                  : "md:p-6 p-4 w-full flex items-center gap-3 hover:bg-grey-400/50"
              }
            >
              <Users width={22} />
              <span className="hidden md:block">
                {t("Home.Nav.TeamManagement")}
              </span>
            </NavLink>
          </>
        )}
      </nav>
      <div className="fixed bottom-5 flex flex-col grow-0 text-base">
        <button
          className="flex gap-3 items-center md:px-6 px-5 py-3 hover:bg-grey-400/50 w-full"
          onClick={toggleLang}
        >
          <Languages width={20} />
          <span className="hidden md:block">{t("Home.Nav.Language")} :</span>

          <div className="font-bold uppercase gap-3 hidden md:flex">
            {[...i18next.languages].sort().map((lang) => {
              const classes =
                lang === i18next.language ? "text-primary" : "text-white";
              return (
                <div className={classes} key={lang}>
                  {lang}
                </div>
              );
            })}
          </div>
        </button>

        <button
          className="flex gap-3 items-center md:px-6 px-5 py-3 hover:bg-grey-400/50 w-full"
          onClick={() => keycloak.logout()}
        >
          <LogOut width={20} />
          <span className="hidden md:block">{t("Home.Nav.SignOut")}</span>
        </button>
      </div>
    </aside>
  );
};

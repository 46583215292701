import { CornerUpRight, Flag, Heart, Info } from "lucide-react";

export type CardProps = {
  id: string;
  src: string;
  author: string;
  date: string;
  likes: number;
  hasMyVote: boolean;
  isVoteDisabled: boolean;
  onLikeHandler: (e: React.MouseEvent) => void;
  onShareHandler: (e: React.MouseEvent) => void;
  onFlagHandler: (e: React.MouseEvent) => void;
  onInfoHandler: (e: React.MouseEvent) => void;
};

export const Card: React.FC<CardProps> = ({
  id,
  src,
  // author,
  // date,
  likes,
  hasMyVote = false,
  isVoteDisabled = false,
  onLikeHandler,
  onShareHandler,
  onFlagHandler,
  onInfoHandler,
}: CardProps) => {
  return (
    <div className="flex flex-col min-w-64 w-64 max-w-64 p-4 m-2 border border-hidden rounded-2xl bg-fnac-dark-gray">
      <img src={src} className="w-full max-h-36" />
      <div className="flex justify-between items-center">
        {/* <div className="flex flex-col"> */}
        {/* <p className="font-gilroy-bold text-lg">Par {author}</p> */}
        {/* <p className="font-gilroy-regular text-sm">{date}</p> */}
        {/* </div> */}
        <div className="flex space-x-2 items-center">
          {isVoteDisabled ? (
            <div>
              <Heart width={18} height={18} />
            </div>
          ) : (
            <button onClick={onLikeHandler} value={id}>
              <Heart
                width={18}
                height={18}
                color={hasMyVote ? "#E9AA00" : "#fff"}
              />
            </button>
          )}
          <span className="font-jakarta font-bold text-sm">{likes}</span>
        </div>
        <div className="flex flex-col space-y-4 justify-between items-end">
          {/* <div className="flex justify-center space-x-2 items-center  mt-[-64px]">
            <span className="font-gilroy-bold text-lg">{likes}</span>
            <button onClick={onLikeHandler} value={id}>
              <Heart width={18} height={18} />
            </button>
          </div> */}
          <div className="flex space-x-2 py-2">
            <button onClick={onShareHandler} value={id}>
              <CornerUpRight width={16} height={16} />
            </button>
            <button onClick={onFlagHandler} value={id}>
              <Flag width={16} height={16} />
            </button>
            <button onClick={onInfoHandler} value={id}>
              <Info width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

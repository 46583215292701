import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Toaster } from "react-hot-toast";
import KeycloakClient from "./helpers/keycloak";
import Router from "./pages/router";

function App() {
  return (
    <ReactKeycloakProvider authClient={KeycloakClient} initOptions={{}}>
      <Toaster />
      <Router />
    </ReactKeycloakProvider>
  );
}

export default App;

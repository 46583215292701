import { Frown } from "lucide-react";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <div className="p-6 md:p-12 md:ml-64 ml-14 mt-20">
      <div className="mt-20 flex flex-col items-center justify-center">
        <Frown className="mb-3 text-red" />
        <h1>{t("NotFound")}</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;

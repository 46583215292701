import React from "react";
import { BeinkLogo } from "../logo/BeinkLogo";
import { FnacLogo } from "../logo/FnacLogo";
import { LittleStampShape } from "./LittleStampShape";

// No translation for the moment as this page is dedicated to Fnac Contest and they ask for French only.
// Moreover, we can't change the langage from this page (by design)
export const FnacClaim: React.FC = () => {
  return (
    <div className="flex-col w-112">
      <div className="h-3/5 bg-no-repeat bg-contain bg-[url('/v2/claim-frame.png')]"></div>
      <div className="h-2/5 flex-col p-8">
        <LittleStampShape label={"Concours"} />
        <div className="font-gilroy-regular font-bold text-white uppercase pt-2 text-xl">
          Pour les 70 ans de la Fnac
        </div>
        <div className="font-ccsign font-bold uppercase text-2xl text-fnac-mustard">
          L’IA au service de votre créativité
        </div>
        <div className="font-gilroy-regular uppercase text-white">
          En partenariat avec
        </div>
        <div className="flex justify-between">
          <BeinkLogo />
          <FnacLogo />
        </div>
      </div>
    </div>
  );
};

export const stripeEnabled: boolean =
  import.meta.env.VITE_FEATURE_STRIPE === "true" || false;
export const inpaintEnabled: boolean =
  import.meta.env.VITE_FEATURE_INPAINT === "true" || false;
export const teamManagementEnabled: boolean =
  import.meta.env.VITE_FEATURE_TEAM === "true" || false;
export const fnacEnabled: boolean =
  import.meta.env.VITE_FEATURE_FNAC_EVENT_STARTED === "true" || false;
export const fnacStopped: boolean =
  import.meta.env.VITE_FEATURE_FNAC_EVENT_ENDED === "true" || false;

import { BeinkLogo } from "./BeinkLogo";
import { FnacLogo } from "./FnacLogo";

export const BothLogo: React.FC = () => {
  return (
    <div className="flex justify-center space-x-2">
      <BeinkLogo />
      <FnacLogo />
    </div>
  );
};

type StampShapeProps = {
  label?: string;
};

export const LittleStampShape: React.FC<StampShapeProps> = ({
  label,
}: StampShapeProps) => {
  return (
    <div className="font-gilroy-black text-center text-white text-xs bg-no-repeat bg-contain bg-[url('/v2/stamp-shape.svg')] w-20 h-6 uppercase">
      {label}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { ArrowDown, ArrowUp, Calendar, Heart } from "lucide-react";
import timeStampParam from "../../../helpers/api";
import { Card } from "./Card";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { intlDateFormater } from "../../../helpers/dateManipulation";
import { ModeratorCard } from "./ModeratorCard";
import { ReportModal } from "../modal/ReportModal";

type GalleryProps = {
  title: string;
  isFnacModerator?: boolean;
  handlePropagateRefresh?: () => void;
};

type Submission = {
  id: string;
  src: string;
  author: string;
  date: Date;
  likes: number;
  votes: Record<string, string>[];
};

type Card = {
  id: string;
  src: string;
  author: string;
  date: Date;
  likes: number;
  hasMyVote: boolean;
};

type Order = "asc" | "desc";
const orderToggler: Record<Order, Order> = {
  asc: "desc",
  desc: "asc",
};
type Sorter = "date" | "like";
type Mode = "normal" | "moderate";
const modeToggler: Record<Mode, Mode> = {
  normal: "moderate",
  moderate: "normal",
};

export const Gallery: React.FC<GalleryProps> = ({
  title,
  isFnacModerator = false,
  handlePropagateRefresh,
}: GalleryProps) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [displayedCards, setDisplayedCards] = useState<Card[]>([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [sorter, setSorter] = useState<Sorter>("date");
  const [likeOrder, setLikeOrder] = useState<Order>("asc");
  const [dateOrder, setDateOrder] = useState<Order>("desc");
  const [mode, setMode] = useState<Mode>("normal");
  const [isReplacement, setIsReplacement] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [reportSubmissionId, setReportSubmissionId] = useState<string>("");

  useEffect(() => {
    if (!keycloak.token) return;

    setIsLoading(true);

    const order = sorter === "date" ? dateOrder : likeOrder;

    fetch(
      `${
        import.meta.env.VITE_API_URL
      }/event/fnac70/submissions${timeStampParam}&page=${page}&order=${order}&sorter=${sorter}&mode=${mode}`,
      {
        headers: { authorization: `Bearer ${keycloak.token}` },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        if (isReplacement) {
          setDisplayedCards(
            data.submissions.map((sub: Submission) => ({
              ...sub,
              hasMyVote: sub.votes.length > 0,
            }))
          );
        } else {
          setDisplayedCards((prev) => [...prev, ...data.submissions]);
          setIsReplacement(true);
        }

        setTotalPage(data.totalPages);
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [keycloak, page, likeOrder, dateOrder, sorter, mode]);

  const toggleLikeFilter = () => {
    setDateOrder("asc");
    setLikeOrder(orderToggler[likeOrder]);
    setSorter("like");
    setPage(1);
  };

  const toggleDateFilter = () => {
    setLikeOrder("asc");
    setDateOrder(orderToggler[dateOrder]);
    setSorter("date");
    setPage(1);
  };

  const toggleSubmissionList = () => {
    setMode(modeToggler[mode]);
    setPage(1);
  };

  const loadMore = () => {
    setIsReplacement(false);
    setPage((prev) => prev + 1);
  };

  const handleOnLike = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    fetch(new URL("/event/fnac70/vote", import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setIsError(true);
        }
        const { event } = data.event;
        setDisplayedCards((prev) =>
          prev.map((card) =>
            card.id === id
              ? {
                  ...card,
                  likes: event === "VOTE" ? card.likes + 1 : card.likes - 1,
                  hasMyVote: !card.hasMyVote,
                }
              : card
          )
        );
      });
  };

  // TODO FNAC @Aurel: Open a popin to add a comment to the report
  const handleOnFlag = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();

    setIsReportModalOpen(true);
    setReportSubmissionId(id);
  };

  const handleOnReportModalClose = () => {
    setIsReportModalOpen(false);
    setReportSubmissionId("");
  };

  const handleOnReportModalSubmit =
    (id: string) => (comment: string) => (e: React.MouseEvent) => {
      e.preventDefault();

      fetch(new URL("/event/fnac70/report", import.meta.env.VITE_API_URL), {
        headers: {
          authorization: `Bearer ${keycloak.token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          comment,
          fromModerator: isFnacModerator,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) return setIsError(true);

          if (mode === "normal") {
            setDisplayedCards((prev) => prev.filter((card) => card.id !== id));
          }

          if (handlePropagateRefresh) {
            handlePropagateRefresh();
          }
        })
        .finally(() => {
          handleOnReportModalClose();
        });
    };

  // TODO FNAC @Aurel: Open a popin to confirm the unflag
  const handleOnUnflag = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Unflag Event on Card:", id);

    fetch(new URL("/event/fnac70/unreport", import.meta.env.VITE_API_URL), {
      headers: {
        authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return setIsError(true);

        if (mode === "moderate") {
          setDisplayedCards((prev) => prev.filter((card) => card.id !== id));
        }

        if (handlePropagateRefresh) {
          handlePropagateRefresh();
        }
      });
  };

  // TODO FNAC @Aurel: Open a popin to select Social Network option
  const handleOnShare = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Share Event on Card:", id);
  };

  // TODO FNAC @Aurel: Open a lateral panel to display additional info (author, date, accurate votes count)
  const handleOnInfo = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Info Event on Card:", id);
  };

  return (
    <div className="flex flex-col">
      <ReportModal
        isOpen={isReportModalOpen}
        onSubmit={handleOnReportModalSubmit(reportSubmissionId)}
        onClose={handleOnReportModalClose}
      />
      <h3 className="flex justify-between items-center px-3.5 py-8">
        <p className="flex font-gilroy-black uppercase text-3xl">{title}</p>
        <div className="flex justify-center items-center space-x-3">
          {isFnacModerator && (
            <button
              className={`flex justify-center items-center space-x-1 font-gilroy-regular text-sm text-center ${
                mode === "normal" ? "bg-fnac-dark-gray" : "bg-fnac-mustard"
              } ${
                mode === "normal" ? "text-fnac-mustard" : "text-fnac-black"
              } p-3 rounded-md`}
              onClick={toggleSubmissionList}
            >
              {mode === "normal"
                ? t("Fnac.Gallery.Moderate")
                : t("Fnac.Gallery.Visible")}
            </button>
          )}
          <p>{t("Fnac.Gallery.Sort")}</p>
          <button
            className="flex justify-center items-center space-x-1 font-gilroy-regular text-sm text-center bg-fnac-dark-gray p-3 rounded-md"
            onClick={toggleLikeFilter}
          >
            <Heart width={12} height={12} />
            <span>Likes</span>
            {likeOrder === "asc" ? (
              <ArrowUp width={12} height={12} />
            ) : (
              <ArrowDown width={12} height={12} />
            )}
          </button>
          <button
            className="flex justify-center items-center space-x-1 font-gilroy-regular text-sm text-center bg-fnac-dark-gray p-3 rounded-md"
            onClick={toggleDateFilter}
          >
            <Calendar width={12} height={12} />
            <span>Date</span>
            {dateOrder === "asc" ? (
              <ArrowUp width={12} height={12} />
            ) : (
              <ArrowDown width={12} height={12} />
            )}
          </button>
        </div>
      </h3>
      {isError && <p className="text-red mb-3">{t("Fnac.Gallery.Error")}</p>}

      {displayedCards.length === 0 && isFnacModerator && mode === "normal" && (
        <p>{t("Fnac.Gallery.NoSubmissionVisibleModerator")}</p>
      )}
      {displayedCards.length === 0 &&
        isFnacModerator &&
        mode === "moderate" && (
          <p>{t("Fnac.Gallery.NoSubmissionReportedModerator")}</p>
        )}
      {displayedCards.length === 0 && !isFnacModerator && (
        <p>
          {t("Fnac.Gallery.NoSubmission")}
          <br />
          <a href="/creator" className="text-fnac-mustard not-italic underline">
            {t("Generations.BtnStart")}
          </a>
        </p>
      )}
      <div className="flex flex-wrap">
        {displayedCards.map(
          ({ id, src, author, date, likes, hasMyVote }: Card, i: number) => {
            return mode === "normal" ? (
              <Card
                key={`${i}-${id}`}
                id={id}
                src={src}
                author={author}
                date={intlDateFormater.format(new Date(date))}
                likes={likes}
                hasMyVote={hasMyVote}
                isVoteDisabled={isFnacModerator}
                onLikeHandler={handleOnLike(id)}
                onShareHandler={handleOnShare(id)}
                onFlagHandler={handleOnFlag(id)}
                onInfoHandler={handleOnInfo(id)}
              />
            ) : (
              <ModeratorCard
                key={`${i}-${id}`}
                id={id}
                src={src}
                likes={likes}
                onUnflagHandler={handleOnUnflag(id)}
                onFlagHandler={handleOnFlag(id)}
                onInfoHandler={handleOnInfo(id)}
              />
            );
          }
        )}
      </div>
      {page < totalPage && (
        <button
          className="p-2 border rounded-md mt-4 block mx-auto hover:bg-primary hover:text-grey"
          onClick={loadMore}
          disabled={isLoading}
        >
          {t("Generations.LoadMore")}
        </button>
      )}
    </div>
  );
};

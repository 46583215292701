import { Outlet } from "react-router-dom";
import { NavBar } from "./navbar/NavBar";
import { Header } from "./header/Header";

export const BeinkLayout = () => (
  <main className="flex flex-col">
    <Header />
    <div className="flex grow">
      <NavBar />
      <div className="flex grow justify-start items-start">
        <Outlet />
      </div>
    </div>
  </main>
);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ModalProps {
  isOpen: boolean;
  onSubmit: (comment: string) => (e: React.MouseEvent) => void;
  onClose: () => void;
}

type Option = {
  id: number;
  label: string;
  value: string;
};

export const ReportModal: React.FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const OPTIONS = [
    { id: 0, label: t("Fnac.ReportOptions.Select"), value: "Value 0" },
    {
      id: 1,
      label: t("Fnac.ReportOptions.Nudity"),
      value: "nudity-sexual",
    },
    {
      id: 2,
      label: t("Fnac.ReportOptions.Violence"),
      value: "violence-brutality",
    },
    { id: 3, label: t("Fnac.ReportOptions.Hatred"), value: "hatred" },
    {
      id: 4,
      label: t("Fnac.ReportOptions.Terrorism"),
      value: "terrorism-extremism",
    },
    {
      id: 5,
      label: t("Fnac.ReportOptions.Fake"),
      value: "fake-news",
    },
    {
      id: 6,
      label: t("Fnac.ReportOptions.Shocking"),
      value: "shocking-disgusting",
    },
    {
      id: 7,
      label: t("Fnac.ReportOptions.Privacy"),
      value: "privacy-violation",
    },
    { id: 8, label: t("Fnac.ReportOptions.Spam"), value: "ad-spam" },
    { id: 9, label: t("Fnac.ReportOptions.Other"), value: "other" },
  ];

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [freeInput, setFreeInput] = useState<string>("");

  const handleOnChangeSelectOption = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();

    setSelectedOption(e.currentTarget.value);
  };

  const handleOnChangeFreeInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    e.preventDefault();

    setFreeInput(e.currentTarget.value);
  };

  if (!isOpen) return null;

  return (
    <div
      className={`z-100 border border-hidden fixed inset-0 bg-fnac-gray bg-opacity-80 backdrop-blur-sm flex items-center justify-center p-4 ${
        isOpen ? "no-doc-scroll" : ""
      }`}
    >
      <div className="beink-radial-gradient rounded-3xl max-w-3xl w-full py-16 px-24">
        <div className="flex flex-col items-center text-center space-y-4">
          <h2 className="text-3xl mb-4 uppercase">
            <span className="font-gilroy-black text-white">
              {t("Fnac.ReportModal.Title1")}
            </span>{" "}
            <span className="font-ccsign font-bold text-fnac-mustard">
              {t("Fnac.ReportModal.Title2")}
            </span>
          </h2>
          <p className="text-white mb-4 font-bold">
            {t("Fnac.ReportModal.TellUsMore")}
          </p>
          <p className="text-white mb-4">
            {t("Fnac.ReportModal.SubmissionWarning")}
          </p>
          <p className="text-white mb-4">
            {t("Fnac.ReportModal.NoOveruseWarning")}
          </p>
          <p className="text-white mb-4">
            {t("Fnac.ReportModal.ContributionThanks")}
          </p>

          <select
            className="font-gilroy-bold bg-transparent border border-white text-white py-4 px-8 rounded-md w-full"
            onChange={handleOnChangeSelectOption}
          >
            {OPTIONS.map((opt: Option) => (
              <option
                key={opt.id}
                value={opt.value}
                className="text-fnac-black"
              >
                {opt.label}
              </option>
            ))}
          </select>

          <textarea
            className="font-gilroy-bold bg-transparent border border-white text-white py-4 px-8 rounded-md  w-full"
            placeholder={t("Fnac.ReportModal.Placeholder")}
            onChange={handleOnChangeFreeInput}
          />

          <div className="flex justify-center items-center space-x-4 p-6">
            <button
              onClick={onClose}
              className="font-gilroy-bold bg-transparent border border-white text-white py-4 px-8 rounded-md"
            >
              {t("Fnac.ReportModal.Cancel")}
            </button>
            <button
              onClick={onSubmit(`${selectedOption} ${freeInput}`.trim())}
              className="font-gilroy-bold bg-fnac-mustard border border-fnac-mustard text-fnac-black py-4 px-8 rounded-md"
            >
              {t("Fnac.ReportModal.Confirm")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { memo } from "react";

export default memo(function Logo({ className }: { className: string }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 180 98"
      className={className}
    >
      <linearGradient
        id="path7_1_"
        gradientUnits="userSpaceOnUse"
        x1="169.2825"
        y1="4.9386"
        x2="232.7825"
        y2="4.9386"
        gradientTransform="matrix(2.8346 0 0 -2.8346 -479.8558 62.9991)"
      >
        <stop offset="0" style={{ stopColor: "#1CF5DA" }} />
        <stop offset="1" style={{ stopColor: "#15F7B7" }} />
      </linearGradient>
      <path
        id="path7"
        style={{ fill: "url(#path7_1_)" }}
        d="M117.2,88.5v-9.5l3.9,0.2c4.5,0.2,5.5,0.4,6.4,1.3c1.1,1.1,1.2,2.1,1.1,9c-0.1,7.2-0.2,7.5-1.8,8.2
	c-0.8,0.3-1.8,0.4-5.2,0.4h-4.3L117.2,88.5z M123.1,94.4c0.3-0.2,0.4-1.1,0.4-5.4c0.1-5.5-0.1-6.5-0.9-6.5c-0.5,0-0.5,0.1-0.5,6.1
	C122.1,94.9,122.2,95,123.1,94.4L123.1,94.4z M130.3,88.5l0.1-9.5l3.9,0.1c3.3,0.1,4.1,0.1,4.9,0.5c1.4,0.7,2,2,2,4.6
	c0,2.3-0.3,3.2-1.2,3.5l-0.7,0.3l0.8,0.7c1,0.9,1.2,1.8,1.2,6.1l0,3H139h-2.4v-3.6c0-3.8-0.2-4.8-0.9-4.8c-0.5,0-0.5,0.1-0.5,4.2V98
	h-2.5h-2.5L130.3,88.5z M136.5,85.8c0.2-0.8,0.2-2.3-0.1-2.8c-0.1-0.3-0.5-0.5-0.7-0.5c-0.4,0-0.5,0.2-0.5,2c0,2,0,2,0.6,1.9
	C136.1,86.3,136.4,86.1,136.5,85.8L136.5,85.8z M143,88.5V79l4.1,0.1l4.1,0.1l0.1,2l0.1,2h-1.7H148v1.7v1.7h1.5h1.5v1.9v1.9h-1.5
	H148v2v2h1.8h1.8v1.9V98h-4.3H143L143,88.5z M151.8,97.7c0-0.7,2.4-18.3,2.5-18.5c0.2-0.3,7-0.3,7.1,0.1c0.1,0.4,2.7,17.9,2.7,18.4
	c0,0.3-0.4,0.4-2.5,0.3l-2.5-0.1l-0.1-1.6l-0.1-1.6h-0.8c-0.7,0-0.8,0.1-0.9,0.9c-0.1,0.5-0.1,1.2-0.1,1.7l0,0.8h-2.6
	C152.4,98,151.8,97.9,151.8,97.7L151.8,97.7z M158.6,90.4c-0.1-0.4-0.2-1.7-0.3-3c-0.1-1.2-0.2-2.2-0.3-2.1
	c-0.1,0.2-0.7,4.5-0.7,5.4c0,0.2,0.2,0.3,0.7,0.3C158.7,91.1,158.7,91.1,158.6,90.4L158.6,90.4z M164.9,88.5l0.1-9.5h3.2h3.2
	l0.5,3.6c0.3,2,0.6,3.8,0.6,4c0,0.3,0.3-1.1,0.5-3c0.2-1.9,0.5-3.7,0.5-4.1l0.1-0.7h3.2h3.2v9.5V98h-2.1h-2.1l0-5.6l0-5.6l-0.8,5.6
	c-0.4,3.1-0.8,5.6-0.8,5.7c0,0-0.7,0-1.6,0l-1.6-0.1l-0.7-5.1l-0.7-5.1l0,5.2l0,5.2h-2.3h-2.3L164.9,88.5z M101.8,91.9
	c-1.3-0.5-2.1-3.3-1.8-6.1c0.4-4.2,1.9-10.2,5.7-22.9c4-13.4,7.6-23.7,11.9-34.4c6-15,11.4-25.2,14.7-27.7c0.9-0.7,2.1-1,3-0.7
	c0.8,0.2,1.2,1.4,1,2.9c-0.2,1.7-1.6,5.6-3.1,8.9c-0.7,1.6-1.4,3.1-1.4,3.3c0,0.2-0.8,1.8-1.6,3.6c-0.8,1.8-1.9,4.3-2.4,5.6
	c-0.8,2.1-0.9,2.7-1,5.1c-0.1,3.2,0,3.5,1.5,3.3c1.4-0.2,8.2-3.7,15.4-8c7.4-4.4,11.7-6.6,13-6.8c0.8-0.1,1,0,1.5,0.5
	c1.4,1.5-0.1,2.9-9.4,8.3c-11.1,6.5-18.1,11.5-23.3,16.8c-4.6,4.6-5.9,7.4-4.3,9.3c1,1.2,3.4,1.9,9.2,2.9c8.2,1.3,11.4,2.2,12.2,3.3
	c1.4,1.7-0.3,4.2-3.4,4.9c-1.7,0.4-5.2,0.1-11.5-0.7c-6.8-0.9-7.6-1-9.5-0.8c-2.3,0.3-3.7,1-4.4,2.2c-1.4,2.4-3.3,8.3-5.3,16.6
	c-0.7,3-1.6,6.2-1.9,7.3C105.5,91.5,103.8,92.8,101.8,91.9L101.8,91.9z M68.9,86.3c-1.7-1.2-2.1-1.8-2.2-3.7
	c-0.1-2.1,0.8-5.8,3.1-12.7c3-9.1,6.8-18.2,9.6-22.9c2.4-4.1,4.4-5.7,6.2-4.9c1.1,0.5,1.4,1.1,1.2,3.3c-0.2,2.6-1.6,6.9-5.3,15.7
	c-3.7,9-4.9,12-5.7,14.3c-0.7,2.4-0.7,2.4-0.1,1.8c1.2-1,4.1-6,10.8-18c9.4-16.9,12.2-21,15.9-23.7c4.8-3.5,9.2-2.8,8.8,1.3
	c-0.2,1.8-1.1,3.4-3.5,5.9c-1.1,1.2-2.4,2.7-2.8,3.3c-1.6,2.4-3,6.3-5.9,17.5c-2.6,9.7-2.9,11.4-3,15.9c-0.1,2.2-0.2,4.2-0.2,4.4
	c-0.1,0.3-0.4,0.6-1,0.8c-1.8,0.6-3.5-0.3-4.5-2.3c-1-2.1-0.8-4.4,1.7-17.3c1.2-6.4,1.8-9.7,1.8-10.7l0-0.7l-0.5,0.6
	c-0.8,1.1-2.4,3.9-6.5,11.4c-4.8,9-4.9,9.1-6.7,11.8c-1.9,3-3.5,4.9-5.6,7.1C71.7,87.3,70.9,87.6,68.9,86.3L68.9,86.3z M0,56
	c0-22.4,0.1-28.2,0.3-28.2c1.7,0,16,1,16.9,1.2c1.8,0.3,2.4,0.6,3.7,1.9c2.1,1.9,2,1.6,2,9.8c0,8,0,8.2-1.5,10.3
	c-0.4,0.6-0.8,1.2-0.8,1.3c0,0.1,0.5,0.9,1.1,1.8c0.7,1.1,1.2,2.1,1.3,3.1c0.3,1.9,0.1,21.9-0.3,23.4c-0.8,3-3,3.6-15.6,3.6l-7.2,0
	L0,56z M13,76.7c0.9-0.6,1.2-1.8,1.5-5.1c0.3-3.8,0.2-11.7-0.2-12.7c-0.8-2-3.4-3.4-4.3-2.4C9.5,57,9.3,60.2,9.1,68.8l-0.2,8.3h1.8
	C11.9,77.1,12.6,77,13,76.7L13,76.7z M13,48.1c1.3-0.9,1.6-2,1.6-5.6c0-4.9-0.6-5.8-3.9-6.1L9,36.3l0.1,5.9c0,3.3,0.2,6.1,0.3,6.2
	C9.7,49,12.2,48.7,13,48.1L13,48.1z M31.3,84c-2.8-0.3-2.9-0.5-3.3-3.7c-0.4-3.8-0.8-23-0.5-27.7c0.5-7.8,1.4-9.6,5.5-10.8
	c4.1-1.2,11.3-0.3,13,1.7c1.3,1.4,1.3,1.8,1.4,13.1l0.1,10.5h-5.9c-4.6,0-5.9,0.1-5.9,0.3c0,0.2,0.1,2.4,0.2,5
	c0.3,5.5,0.4,5.9,1.9,5.9c1.8,0,2.5-1.3,2.8-5.2c0.2-3.3,0.2-3.2,4.4-3.3c3-0.1,3.5,0,3.5,0.3c0,1.4-0.8,9.1-1.1,10.5
	c-0.5,2.3-0.9,2.8-2.6,3.2C43,84,33.6,84.3,31.3,84L31.3,84z M40.3,58.9c0.7-0.7,0-8.3-0.8-9.3c-0.3-0.4-0.7-0.5-2.1-0.5h-1.7v5v5
	h2.2C39.2,59.2,40.1,59.1,40.3,58.9z M53.4,81.5c0-3.3,0.5-6.3,1.9-10.5c1.1-3.3,3.1-7.5,4.4-9.3c1.3-1.7,3.1-3.2,3.9-3.2
	c1.8,0,1.5,3.8-1,14.6c-1.3,5.7-2.2,8.5-2.9,9.5c-0.7,1-1.7,1.3-4.2,1.5l-2.1,0.2L53.4,81.5z M66.3,50.4c-0.7-0.3-1-1.2-1-2.5
	c0-3.1,3.6-5.8,5.4-4c0.6,0.6,0.6,0.7,0.5,2.4c-0.1,2.2-0.7,3.2-2.1,3.9C67.9,50.7,67.1,50.8,66.3,50.4L66.3,50.4z"
      />
    </svg>
  );
});

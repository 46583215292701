import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Nav from "../components/Nav";

const BeinkLayout = () => (
  <main>
    <Header />
    <Nav />
    <Outlet />
  </main>
);

export default BeinkLayout;

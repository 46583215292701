import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Member, UserRole } from "../types";
import { teamManagementEnabled } from "../helpers/FeatureFlags";
import { Navigate } from "react-router-dom";

const TeamManagementPage = () => {
  const { t } = useTranslation();

  const { keycloak } = useKeycloak();
  const userRoles: string[] = keycloak.realmAccess?.roles || [];
  const isTeamManager = userRoles.includes(UserRole.TeamManager);

  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(`${import.meta.env.VITE_API_URL}/my-team-members`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        setMembers(data as Member[]);
      })
      .catch(console.error);
  }, [keycloak]);

  if (!teamManagementEnabled || !isTeamManager) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="p-6 md:p-12 md:ml-64 ml-14 mt-20">
      <div className="p-6">
        <div className="mb-12 text-center text-balance">
          <h2 className="mb-4 text-4xl font-extrabold text-primary leading-tight">
            {t("Team.Title")}
          </h2>
          <p className="mb-4 font-light">{t("Team.Description")}</p>
        </div>
        <div className="flex">
          <div className="flex-grow mb-4 font-extrabold text-2xl md:text-3xl">
            {t("Team.Stats.Title")}
          </div>
        </div>
        <div className="mb-4 flex flex-col space-between bg-grey rounded-lg border border-grey-200 shadow-md">
          <div className="flex flex-col lg:flex-row lg:justify-between p-4 rounded-md text-lg">
            <div className="p-4 flex flex-col md:flex-row">
              <div className="font-bold grow md:mr-2">
                {t("Team.Stats.Count")}
              </div>
              <div className="font-bold text-center md:text-right">
                {members.length}
              </div>
            </div>
          </div>
        </div>
        <div className="font-extrabold text-2xl md:text-3xl mb-4">
          {t("Team.Title")}
        </div>
        <div className="flex bg-grey rounded-lg border border-grey-200 shadow-md">
          <MemberList members={members} />
        </div>
      </div>
    </div>
  );
};

export default TeamManagementPage;

const MemberList = ({ members }: { members: Member[] }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col">
      <div className="hidden lg:flex justify-between p-4 rounded-md font-bold text-xl underline underline-offset-4">
        <div className="p-4 grow">{t("Team.Metadata.User")}</div>
        <div className="flex flex-row">
          <div className="p-4">{t("Team.Metadata.Role")}</div>
        </div>
      </div>
      {members.map((member: Member) => (
        <div
          key={member.id}
          className="flex flex-col lg:flex-row justify-between p-4 rounded-md"
        >
          <div className="px-4">
            <div className="text-xl font-bold">{`${member.firstName} ${member.lastName}`}</div>
            <div className="italic text-medium truncate">{member.email}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="px-4 py-2">
              {member.role.some((role: string) => role === UserRole.TeamManager)
                ? t("Team.Role.Manager")
                : t("Team.Role.User")}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

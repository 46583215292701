import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type ModeratorHeaderCardProps = {
  shouldResfreshStats: number;
};

export const ModeratorHeaderCard: React.FC<ModeratorHeaderCardProps> = ({
  shouldResfreshStats,
}: ModeratorHeaderCardProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const [totalSubmissions, setTotalSubmissions] = useState<number>(0);
  const [visibleSubmissions, setVisibleSubmissions] = useState<number>(0);
  const [reportedSubmissions, setReportedSubmissions] = useState<number>(0);
  const [blockedSubmissions, setBlockedSubmissions] = useState<number>(0);

  useEffect(() => {
    if (!keycloak.token) return;

    fetch(`${import.meta.env.VITE_API_URL}/event/fnac70/moderator-info`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) return;

        setTotalSubmissions(data.totalSubmissions);
        setVisibleSubmissions(data.visibleSubmissions);
        setReportedSubmissions(data.reportedSubmissions);
        setBlockedSubmissions(data.blockedSubmissions);
      })
      .catch((err) => {
        console.error("Oups, An error has occurred", err);
      });
  }, [keycloak, shouldResfreshStats]);

  return (
    <div className="flex justify-between py-8">
      <div className="flex flex-col text-3xl uppercase pr-10 min-w-64 max-w-64 w-64 xl:min-w-96 xl:max-w-96 xl:w-96">
        <h1 className="font-gilroy-bold text-white">
          {t("Fnac.MyContest.Moderator.Title")}
        </h1>
        <p className="font-ccsign font-bold text-fnac-mustard">
          {t("Fnac.MyContest.Fnac70")}
        </p>
      </div>
      <div className="flex flex-col text-base uppercase px-4 text-right">
        <p>
          <span className="font-gilroy-bold text-white">
            {t("Fnac.MyContest.Moderator.TotalSubmissions")}
          </span>{" "}
          <span className="font-gilroy-bold text-fnac-mustard">
            {totalSubmissions}
          </span>
        </p>
        <p>
          <span className="font-gilroy-bold text-white">
            {t("Fnac.MyContest.Moderator.VisibleSubmissions")}
          </span>{" "}
          <span className="font-gilroy-bold text-fnac-mustard">
            {visibleSubmissions}
          </span>
        </p>
        <p>
          <span className="font-gilroy-bold text-white">
            {t("Fnac.MyContest.Moderator.ReportedSubmissions")}
          </span>{" "}
          <span className="font-gilroy-bold text-fnac-mustard">
            {reportedSubmissions}
          </span>
        </p>
        <p>
          <span className="font-gilroy-bold text-white">
            {t("Fnac.MyContest.Moderator.BlockedSubmissions")}
          </span>{" "}
          <span className="font-gilroy-bold text-fnac-mustard">
            {blockedSubmissions}
          </span>
        </p>
      </div>
    </div>
  );
};

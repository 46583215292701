import { SquareArrowOutUpRight } from "lucide-react";
import { useTranslation } from "react-i18next";

// TODO @Aurel: I keep the existing behavior with a full navigation (link) instead an internal SPA navigation (Router)
export const CreatorCTA: React.FC = () => {
  const { t } = useTranslation();
  return (
    <a
      href={"/creator"}
      className="flex space-x-2 justify-center items-center bg-fnac-mustard px-6 py-4 text-sm font-gilroy-bold border border-hidden rounded-md"
    >
      <span className="text-fnac-black">{t("Header.CreatorCTA")}</span>
      <SquareArrowOutUpRight width={12} height={12} color="#13100D" />
    </a>
  );
};

type StampShapeProps = {
  label?: string;
};

export const MediumStampShape: React.FC<StampShapeProps> = ({
  label,
}: StampShapeProps) => {
  return (
    <div className="font-gilroy-black text-center text-white text-base bg-no-repeat bg-contain bg-[url('/v2/stamp-shape.svg')] w-32 h-7 uppercase">
      {label}
    </div>
  );
};

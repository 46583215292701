import { NavLink } from "react-router-dom";
import { BothLogo } from "../../logo/BothLogo";
import { CreatorCTA } from "./CreatorCTA";

export const Header: React.FC = () => {
  return (
    <div className="flex justify-between items-center py-4 px-6 bg-fnac-dark-gray">
      <NavLink to="/">
        <BothLogo />
      </NavLink>
      <CreatorCTA />
    </div>
  );
};

import { Download, Link, MessageCircle, Share2 } from "lucide-react";
import { useTranslation } from "react-i18next";

type ShareBoxProps = {
  isLight?: boolean;
};

export const ShareBox: React.FC<ShareBoxProps> = (
  { isLight } = { isLight: false }
) => {
  const { t } = useTranslation();

  const handleShareOnSocial_1 = () => {
    console.log("Share on Social Media 1");
  };
  const handleShareOnSocial_2 = () => {
    console.log("Share on Social Media 2");
  };
  const handleShareOnSocial_3 = () => {
    console.log("Share on Social Media 3");
  };

  const handleDownload = () => {
    console.log("Download my card");
  };

  return (
    <div
      className={`flex flex-col justify-center ${
        isLight ? "bg-white" : "bg-fnac-dark-gray"
      } py-6 w-56 border border-hidden rounded-xl space-y-4 min-h-40 h-40 max-h-40`}
    >
      <p className="text-center uppercase text-sm">
        <span className="font-ccsign font-bold text-fnac-mustard">
          {t("Fnac.MyContest.Share")}
        </span>{" "}
        <span
          className={`font-gilroy-bold ${
            isLight ? "text-fnac-black" : "text-white"
          }`}
        >
          {t("Fnac.MyContest.MyDesign")}
        </span>
      </p>
      <div className="flex justify-center space-x-4 items-center">
        <button
          className="border border-fnac-black rounded-full bg-white p-3"
          onClick={handleShareOnSocial_1}
        >
          <Link width={16} height={16} color={"#13100D"} />
        </button>
        <button
          className="border border-fnac-black rounded-full bg-white p-3"
          onClick={handleShareOnSocial_2}
        >
          <Share2 width={16} height={16} color={"#13100D"} />
        </button>
        <button
          className="border border-fnac-black rounded-full bg-white p-3"
          onClick={handleShareOnSocial_3}
        >
          <MessageCircle width={16} height={16} color={"#13100D"} />
        </button>
      </div>
      <div className="flex justify-center">
        <button
          className={`flex justify-center space-x-2 items-center  border border-hidden rounded-md ${
            isLight ? "bg-fnac-mustard" : "bg-white"
          } p-3`}
          onClick={handleDownload}
        >
          <Download width={12} height={12} color={"#13100D"} />
          <span className="font-gilroy-bold text-fnac-black text-xs">
            {t("Fnac.MyContest.Download")}
          </span>
        </button>
      </div>
    </div>
  );
};

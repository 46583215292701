import { Check, Flag, Heart, Info } from "lucide-react";

export type ModeratorCardProps = {
  id: string;
  src: string;
  likes: number;
  onUnflagHandler: (e: React.MouseEvent) => void;
  onFlagHandler: (e: React.MouseEvent) => void;
  onInfoHandler: (e: React.MouseEvent) => void;
};

export const ModeratorCard: React.FC<ModeratorCardProps> = ({
  id,
  src,
  likes,
  onUnflagHandler,
  onFlagHandler,
  onInfoHandler,
}: ModeratorCardProps) => {
  return (
    <div className="flex flex-col min-w-64 w-64 max-w-64 p-4 m-2 border border-hidden rounded-2xl bg-fnac-dark-gray">
      <img src={src} className="w-full max-h-36" />
      <div className="flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          <div>
            <Heart width={18} height={18} />
          </div>
          <span className="font-jakarta font-bold text-sm">{likes}</span>
        </div>
        <div className="flex flex-col space-y-4 justify-between items-end">
          <div className="flex space-x-2 py-2">
            <button onClick={onUnflagHandler} value={id}>
              <Check width={16} height={16} />
            </button>
            <button onClick={onFlagHandler} value={id}>
              <Flag width={16} height={16} />
            </button>
            <button onClick={onInfoHandler} value={id}>
              <Info width={16} height={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

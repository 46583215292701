import { Info, SquareArrowOutUpRight } from "lucide-react";
import { FnacLogo } from "../logo/FnacLogo";
import { MediumStampShape } from "./MediumStampShape";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { startTransition, useCallback } from "react";

export const FreshChallengerHeaderCard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleParticipate = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      console.log("Open Beink Creator");
      startTransition(() => {
        navigate("/creator");
      });
    },
    [navigate]
  );

  // TODO FNAC @Aurel: Navigate to Contest Information (CGU?)
  const handleMoreInfo = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Open Contest Information");
  };

  return (
    <div className="py-8">
      <div className="flex bg-fnac-black w-188 pb-4 mx-auto border border-hidden rounded-lg overflow-hidden">
        <div className="w-56 h-56 shrink-0 bg-no-repeat bg-contain bg-[url('/v2/claim-frame.png')]"></div>
        <div className="flex flex-col space-y-4 px-8 mt-8">
          <MediumStampShape label="Concours" />
          <h2 className="text-3xl uppercase w-112">
            <span className="font-gilroy-black">
              {t("Fnac.Contest.Create")}
            </span>{" "}
            <span className="font-ccsign font-bold text-fnac-mustard">
              {t("Fnac.Contest.FnacCard")}
            </span>
          </h2>
          <div className="flex justify-between items-start pt-2">
            <div className="flex justify-start items-center space-x-4 font-gilroy-bold text-xs">
              <button
                className="flex justify-center items-center space-x-2 py-3 px-4 bg-fnac-mustard text-fnac-black border border-hidden rounded"
                onClick={handleParticipate}
              >
                <span>{t("Fnac.Contest.Participate")}</span>
                <SquareArrowOutUpRight width={12} height={12} color="#13100D" />
              </button>
              <button
                className="flex justify-center items-center space-x-2 py-3 px-4 bg-fnac-gray border border-hidden rounded"
                onClick={handleMoreInfo}
              >
                <span>{t("Fnac.Contest.Infos")}</span>
                <Info width={12} height={12} />
              </button>
            </div>
            <div className="flex self-end">
              <FnacLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { $generationsLeft } from "../store/generations-left";
import timeStampParam from "./api";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { $email, $firstName, $lastName } from "../store/user";

export const ProtectedLayout = () => {
  const location = useLocation();

  const currentLocationState = location.state || { from: { pathname: "/" } };

  const { initialized, keycloak } = useKeycloak();
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!keycloak?.authenticated) return;

    fetch(`${import.meta.env.VITE_API_URL}/user-info${timeStampParam}`, {
      headers: { authorization: `Bearer ${keycloak.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setIsError(true);
        }
        $generationsLeft.set(data.generationsLeft);

        $firstName.set(data.user.firstName);
        $lastName.set(data.user.lastName);
        $email.set(data.user.email);
      })
      .catch(() => setIsError(true));
  }, [initialized, keycloak]);

  if (isError) {
    console.error("An error has occurred while retrieving user infos.");
  }

  if (!initialized) {
    return (
      <div className="w-dvw h-dvh bg-grey flex flex-col items-center justify-center">
        <div className="w-full mb-4">
          <Loader2 className="animate-spin text-primary mx-auto" size={40} />
        </div>
        <p className="text-xl text-center">{t("Beink.Loading.title")}</p>
      </div>
    );
  }

  if (!keycloak?.authenticated) {
    keycloak?.login();
    return <Navigate to={currentLocationState.from} />;
  }

  return <Outlet />;
};
